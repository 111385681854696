<template>
  <div>
    <div class="card-toolbar mb-5">

      <router-link   v-if="$can('agents.create')" to="/agents/agents/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('agents.add_agent') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name">{{ $t('agents.name') }}</label>
              <input v-model="filters.name" type="text" id="name" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="email">{{ $t('agents.email') }}</label>
              <input v-model="filters.email" type="text" id="email" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="phone">{{ $t('agents.mobile') }}</label>
              <input v-model="filters.mobile" type="text" id="phone" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="from_date">{{ $t('agents.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="to_date">{{ $t('agents.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('agents.country') }}</label>
              <div class="input-group">
                <multiselect
                    v-model="country"
                    :placeholder="$t('agents.country')"
                    label="name"
                    track-by="id"
                    :options="countries"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    @search-change="getCountries($event)"
                    :internal-search="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="name">{{ $t('commission.agent_name') }}</label>
              <multiselect
                  v-model="agent"
                  :placeholder="$t('commission.agent_name')"
                  label="name"
                  track-by="id"
                  :options="agents"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getAgents($event)"
                  :internal-search="false"
              >
              </multiselect>
            </div>
            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
<!--        <div class="row justify-content-end p-4">-->
<!--          <export-data :data-list="dataList" :fields="json_fields"-->
<!--                       :file-name="$t('agents.agents_management')"></export-data>-->
<!--        </div>-->

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="mobile" slot-scope="props">
            <span class="tel-span">{{props.row.mobile}}</span>
          </template>
          <template slot="status" slot-scope="props">
<!--            v-if="$can('agents.update')"-->
            <b-form-checkbox size="lg"
                             @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active"
                             :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
          </template>

          <template slot="actions" slot-scope="props">

            <v-btn v-b-tooltip.hover v-if="$can('agents.update')" :title="$t('edit')"  icon color="pink" :to="`/agents/agents/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
            </v-btn>

            <v-icon small v-if="$can('agents.delete')" class="text-danger" v-b-tooltip.hover :title="$t('delete')" @click="deleteItem(props.row)">mdi-delete
            </v-icon>
            <b-dropdown  id="dropdown-offset" :text="$t('more_actions')" variant="outline-primary" class="m-2">

              <button v-if="$can('agents.update')" class="dropdown-item" @click="showModal(props.row)">{{$t('referral_link')}}</button>
              <router-link  class="dropdown-item" :to="`/agents/commission/create/${props.row.id}`">{{$t('commission.commission')}}</router-link>

            </b-dropdown>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::User-->
    <b-modal ref="modal" hide-footer :title="$t('referral_link')">
      <div class="d-flex input-group mb-5">
        <input type="text" v-model="link" class="form-control" :disabled="true">
        <v-btn v-b-tooltip.hover :title="tooltip_text" @click="copyText" class="btn btn-primary btn-lg text-white" style="text-transform: unset;">
          <i class="fa fa-copy"></i>
        </v-btn>
      </div>

      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
        <b-button class="ml-2 mt-2 mr-2" variant="dark" @click="generateNewKey">{{$t('generate_key')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-agents",
  components: {},
  data() {
    return {
      mainRoute: 'agents/agents',
      subMainRoute: 'agents/agent',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      dataList: [],

      filter: {
        sortBy: 'id',
      },
      filters: {
        agent_id: null,
        name: null,
        email: null,
        country_id: null,
        mobile: null,
        from_date: null,
        to_date: null,
      },
      columns: ['name', 'email', 'mobile', 'no_of_customer', 'created_at', 'total_sales_amount', 'status', 'actions'],
      data: [],

      country: null,
      countries: [],

      link: null,
      tooltip_text: this.$t('copy'),
      inner_id: null,
    }
  },
  computed: {
    // json_fields: function () {
    //   let fields = {};
    //   fields[this.$t('users.name')] = 'name';
    //   fields[this.$t('users.email')] = 'email';
    //   fields[this.$t('users.created_at')] = 'created_at';
    //
    //   return fields;
    // },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          full_name: that.$t('agents.full_name'),
          email: that.$t('agents.email'),
          mobile: that.$t('agents.mobile'),
          no_of_customer: that.$t('agents.no_of_customer'),
          created_at: that.$t('agents.created_at'),
          total_sales_amount: that.$t('agents.total_sales_amount'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query("agents/agents", {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    "country": function (val) {
      if (val && val.id) {
        this.filters.country_id = val.id;
      }else {
        this.filters.country_id = null;
      }
    },

  },
  // created() {
  //     ApiService.get('users').then(response => {
  //         this.data = response.data.data.data;
  //     }).catch(e => {
  //         console.log(e);
  //     });
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.agents_management")}]);
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.email = null;
      this.filters.country_id = null;
      this.filters.mobile = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.country = null;

      this.$refs.table.refresh();
    },

    getFetch() {
      this.$refs.table.refresh();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute+`/${item.id}`).then(response => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        is_active: (status ? true : false),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },

    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(this.mainRouteDependency+"/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },

    showModal(data) {
      this.inner_id = data.id;
      this.link = data.invite_link;
      this.$refs['modal'].show();
    },
    hideModal() {
      this.$refs['modal'].hide();
      this.inner_id = null;
      this.link = null;
    },
    copyText(){
      navigator.clipboard.writeText(this.link);
      this.tooltip_text = this.$t('copied')+': '+this.link;
      setTimeout(()=>{
        this.tooltip_text = this.$t('copy');
      }, 1000);
    },
    generateNewKey(){
      ApiService.patch(this.subMainRoute + '/invite/' + this.inner_id).then(response => {
        this.setRowData();
        this.$refs.table.refresh();
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    setRowData(){
      ApiService.patch(this.mainRoute + '/' + this.inner_id).then(response => {
        this.link = response.data.data.invite_link;
      })
    },
  },
};
</script>
